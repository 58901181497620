<template>
  <div class="wordDictation">
    <div v-if="total > 0">
      <el-table
        :border="false"
        :data="tableData"
        row-class-name="rowClass"
      >
        <el-table-column
          prop="name"
          label="名称"
          align="center"
          width="180"
        />
        <el-table-column
          label="考试状态"
          align="center"
          width="180"
        >
          <template #default="{row}">
            <div>{{ handleExamStatus(row) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          width="360"
          label="听写时间"
        >
          <template #default="{row}">
            <div>{{ row.startTime }} - {{ row.endTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="提交状态"
        >
          <template #default="{row}">
            <div>{{ row.status === 0 ? '未提交' : '已提交' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="考试结果"
        >
          <template #default="{row}">
            <div v-if="row.status === 1 && row.publishFlag === 1">
              <span style="color: #17A940">{{ row.correctQuantity }}</span> /
              <span>{{ row.totalNumber }}</span>
            </div>
            <div v-else>暂无</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template #default="{row}">
            <el-button
              type="text"
              :class="{btnNoData:actionButtonText(row) === '--'}"
              :disabled="actionButtonText(row) === '--'"
              @click="viewTestPaper(row)"
            >
              {{ actionButtonText(row) }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next,jumper"
          :total="total"
          @current-change="currentChange"
        />
      </div>
    </div>
    <el-empty v-else style="min-height: 500px" class="none-box" description="暂无数据" :image="require('@/assets/images/empty.png')" />
    <indexBottom />
  </div>
</template>

<script>

import indexBottom from '@/components/indexBottom'
import { getWordTestList } from '@/api/course/word'
import { mapState } from 'vuex'

export default {
  name: 'WordDictation',
  components: { indexBottom },
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1
    }
  },
  computed: {
    ...mapState({
      wordMastery: state => state.dict.dictTree.word_mastery['arr'],
      userInfo: state => state.user.userInfo
    })
  },
  created() {
    this.getList()
  },
  methods: {
    currentChange(page) {
      this.pageNum = page
      this.getList()
    },
    getList() {
      getWordTestList({
        classId: this.userInfo.classId,
        stuId: this.userInfo.id,
        pageSize: 10,
        pageNum: this.pageNum
      }).then(res => {
        this.tableData = res.rows
        this.total = res.total
      })
    },
    actionButtonText(row) {
      const examStatus = this.handleExamStatus(row)
      if (examStatus === '未开始') {
        return '--'
      } else {
        // return row.status === 0 ? '--' : '查看试卷'
        return '查看试卷'
      }
    },
    viewTestPaper(row) {
      this.$router.push({
        path: '/viewTestPaper',
        query: { id: row.id }
      })
    },
    handleExamStatus(row) {
      const moment = this.$moment
      const startTime = row.startTime
      const endTime = row.endTime
      const date = new Date()
      if (moment(date).diff(moment(endTime), 'minutes', true) > 0) {
        return '已结束'
      }
      if (moment(date).diff(moment(startTime), 'minutes', true) < 0) {
        return '未开始'
      }
      return '考试中'
    }
  }
}
</script>

<style scoped lang="less">
.wordDictation {
  ::v-deep .el-table {
    width: 1200px;
    margin: 20px auto 0px;
    min-height: 500px;
    background-color: #EDF1F7;

    .el-table__body {
      border-collapse: separate;
      border-spacing: 0 12px;
      background-color: #EDF1F7;
    }

    th, td {
      border-bottom: none;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    th {
      font-size: 16px;
    }

    .rowClass {
      border-right: 4px;
      //margin-bottom: 20px;
    }

    .btnNoData {
      //font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .pagination{
    width: 1200px;
    padding: 30px 0;
    text-align: right;
  }
}
</style>
