var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wordDictation"},[(_vm.total > 0)?_c('div',[_c('el-table',{attrs:{"border":false,"data":_vm.tableData,"row-class-name":"rowClass"}},[_c('el-table-column',{attrs:{"prop":"name","label":"名称","align":"center","width":"180"}}),_c('el-table-column',{attrs:{"label":"考试状态","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.handleExamStatus(row)))])]}}],null,false,2601565075)}),_c('el-table-column',{attrs:{"prop":"address","align":"center","width":"360","label":"听写时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.startTime)+" - "+_vm._s(row.endTime))])]}}],null,false,71471864)}),_c('el-table-column',{attrs:{"prop":"status","align":"center","label":"提交状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.status === 0 ? '未提交' : '已提交'))])]}}],null,false,3541121335)}),_c('el-table-column',{attrs:{"prop":"address","align":"center","label":"考试结果"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 1 && row.publishFlag === 1)?_c('div',[_c('span',{staticStyle:{"color":"#17A940"}},[_vm._v(_vm._s(row.correctQuantity))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(row.totalNumber))])]):_c('div',[_vm._v("暂无")])]}}],null,false,329240053)}),_c('el-table-column',{attrs:{"label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{class:{btnNoData:_vm.actionButtonText(row) === '--'},attrs:{"type":"text","disabled":_vm.actionButtonText(row) === '--'},on:{"click":function($event){return _vm.viewTestPaper(row)}}},[_vm._v(" "+_vm._s(_vm.actionButtonText(row))+" ")])]}}],null,false,2823531462)})],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next,jumper","total":_vm.total},on:{"current-change":_vm.currentChange}})],1)],1):_c('el-empty',{staticClass:"none-box",staticStyle:{"min-height":"500px"},attrs:{"description":"暂无数据","image":require('@/assets/images/empty.png')}}),_c('indexBottom')],1)}
var staticRenderFns = []

export { render, staticRenderFns }